import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

export interface CheckCanDeactivate {
  canDeactivate(): Observable<boolean>;
}

//  CanDeactivateFn<CheckCanDeactivate>
export function beforeDeactivated(component: CheckCanDeactivate, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot): Observable<boolean> {
  const canLeave = component.canDeactivate();
  if (!canLeave) {
    // 브라우저 히스토리를 보존하는 논리
    history.pushState(null, '', '');
  }

  return canLeave;

}
